@use '@angular/material' as mat;
@use '@iris/styles/src/scss/material/mat-variables' as iris-vars;
@use '@iris/styles/src/scss/material/iris-theme' as iris-themes;

.sk {
  @include mat.icon-button-density(iris-vars.$icon-button-density);
  .sk-theme-light & {
    @include mat.all-component-colors(iris-themes.$iris-theme);
  }
  .sk-theme-dark & {
      @include mat.all-component-colors(iris-themes.$iris-theme-dark);
  }
}
